<template>
  <v-container>
    <div class="d-flex justify-end">
      <v-btn color="primary" :to="{ name: 'Major.Create' }">New Major</v-btn>
    </div>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>Major</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-form @submit.prevent="options.search = searchForm">
          <v-text-field
            v-model="searchForm"
            @click:append="clearSearch"
            :append-icon="searchForm ? 'mdi-close' : ''"
            prepend-icon="mdi-magnify"
            :label="$vuetify.lang.t('$vuetify.search')"
            single-line
            hide-details
          >
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <data-table
      :headers="headers"
      :items="majors"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          first-icon="mdi-chevron-double-left"
          last-icon="mdi-chevron-double-right"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          :items-per-page-options="[25, 50, 100, -1]"
          :options="options"
          :pagination="pagination"
          :show-first-last-page="true"
          :show-current-page="true"
        />
      </template>
      <template v-slot:[`item.action`]="{ item: { id } }">
        <div style="width: 100px">
          <v-btn
            color="warning"
            :to="{ name: 'Major.Edit', params: { id } }"
            class="mt-1 mb-2"
            medium
            block
            >Edit</v-btn
          >
          <v-btn color="error" class="mb-1" @click="deletePrompt(id)" block
            >Delete</v-btn
          >
        </div>
      </template>
    </data-table>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Delete selected data? </v-card-title>

        <v-card-text>
          This action is irreversible. Deleting this data means other data that
          containing this data will also be deleted.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error darken-1"
            @click="deleteData()"
            :loading="loadingDelete"
            text
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import DataTable from "@/components/DataTable.js";
import Major from "@/services/major";

export default {
  name: "Major.Index",
  components: {
    DataTable
  },
  data() {
    return {
      totalData: 0,
      majors: [],
      loading: true,
      loadingDelete: false,
      options: {
        search: undefined
      },
      headers: [
        { text: "Code", value: "code" },
        { text: "Name", value: "name" },
        { text: "Group", value: "group" },
        { text: "Score", value: "score" },
        { text: "Action", value: "action", sortable: false }
      ],
      dialog: false,
      selectedId: "",
      searchForm: null
    };
  },
  watch: {
    options: {
      handler() {
        this.getMajors();
      },
      deep: true
    }
  },
  created() {
    this.setNavbarTitle("Manage Major");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getMajors() {
      this.loading = true;
      this.majors = [];
      const majors = await Major.index(this.options);
      this.majors = majors.data.data.majors;
      this.totalData = majors.data.data.total_data;
      this.loading = false;
    },
    async deletePrompt(id) {
      this.selectedId = id;
      this.dialog = true;
    },
    async deleteData() {
      try {
        this.loadingDelete = true;
        await Major.delete(this.selectedId);
        this.$router.go(this.$router.currentRoute);
      } catch (error) {
        this.loadingDelete = false;
      }
    },
    clearSearch() {
      this.options.search = undefined;
      this.searchForm = null;
    }
  }
};
</script>
